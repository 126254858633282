import ctl from "@netlify/classnames-template-literals";

export const descriptionText = ctl(`
  relative
  min-w-full
  top-[8em]
  -left-[65em]
  px-12
  items-center
  text-center
`);

export const root = ctl(`
  flex
  items-center
`);

export const gasTankWrapper = ctl(`
  w-full
  self-center
`);

export const percentageTextWrapper = ctl(`
  relative
  min-w-full
  top-[2em]
  -left-[32.5em]
  flex
  flex-col
  gap-4
  items-center
  text-center
`);
