import { Text } from "@olivahealth/oli-ui";
import { useTranslation } from "react-i18next";
import * as s from "./styles";
import GasTankShape, { GasTankBackgroundColor } from "./GasTankShape";

interface Props {
  percentage: number;
  showDescription?: boolean;
  userName?: string;
}

export enum ScoreLevel {
  LOW,
  MODERATE,
  HIGH,
}

const ELEMENT_WIDTH = 520; // This component is very sensitive to changes in width and height as the calculations to apply the mask are based on these values
const ELEMENT_HEIGHT = 340;
const MEDIUM_THRESHOLD = 56;
const HIGH_THRESHOLD = 71;

export default function WellbeingProfileGasTank({
  percentage,
  showDescription = true,
  userName,
}: Props) {
  const { t } = useTranslation("wellbeingProfile", {
    keyPrefix: "gasTank",
  });

  let scoreLevel: ScoreLevel;

  if (percentage < MEDIUM_THRESHOLD) {
    scoreLevel = ScoreLevel.LOW;
  } else if (percentage < HIGH_THRESHOLD) {
    scoreLevel = ScoreLevel.MODERATE;
  } else {
    scoreLevel = ScoreLevel.HIGH;
  }

  const detailsPerScoreLevel = {
    [ScoreLevel.LOW]: {
      title: t("LOW.title"),
      description: t(`LOW.description${userName ? ".external" : ""}`, {
        userName,
      }),
      backgroundColor: GasTankBackgroundColor.BROWN,
    },
    [ScoreLevel.MODERATE]: {
      title: t("MODERATE.title"),
      description: t(`MODERATE.description${userName ? ".external" : ""}`, {
        userName,
      }),
      backgroundColor: GasTankBackgroundColor.PURPLE,
    },
    [ScoreLevel.HIGH]: {
      title: t("HIGH.title"),
      description: t(`HIGH.description${userName ? ".external" : ""}`, {
        userName,
      }),
      backgroundColor: GasTankBackgroundColor.GREEN,
    },
  };

  return (
    <div>
      <div
        className={s.root}
        style={{
          maxWidth: `${ELEMENT_WIDTH}px`,
          maxHeight: `${ELEMENT_HEIGHT}px`,
        }}
      >
        <div className={s.gasTankWrapper}>
          <GasTankShape
            width={ELEMENT_WIDTH}
            height={ELEMENT_HEIGHT}
            percentage={percentage}
            detailsPerScoreLevel={detailsPerScoreLevel[scoreLevel]}
          />
        </div>
        <div className={s.percentageTextWrapper}>
          <Text color="white" size="xl">
            {Math.ceil(percentage)}%
          </Text>
          <Text
            color="white"
            size="4xl"
            weight="bold"
            fontFamily="centra"
            gutter="lg"
          >
            {detailsPerScoreLevel[scoreLevel].title}
          </Text>
        </div>
        {showDescription && (
          <div className={s.descriptionText}>
            <Text color="white" size="xl" align="center">
              {detailsPerScoreLevel[scoreLevel].description}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
